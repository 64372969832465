import { render, staticRenderFns } from "./es.vue?vue&type=template&id=775345a9&scoped=true&"
var script = {}
import style0 from "./es.vue?vue&type=style&index=0&id=775345a9&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "775345a9",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/nginx/coral/coral-public/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('775345a9')) {
      api.createRecord('775345a9', component.options)
    } else {
      api.reload('775345a9', component.options)
    }
    module.hot.accept("./es.vue?vue&type=template&id=775345a9&scoped=true&", function () {
      api.rerender('775345a9', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Help/components/es.vue"
export default component.exports