var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", [_vm._v("Idiomas disponibles: inglés, ruso")]),
      _c("div", [_vm._v("Telegram, Viber, WhatsApp: +1(437)229-3572")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }